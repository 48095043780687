import { useRouter } from 'next/router'
import { sessionStorage } from 'react-storage'
import { forEach, groupBy } from 'lodash'
import {
  GTMEventCategory,
  GTMEventTypes,
  EventValues,
} from '@sial/common-utils'

import {
  GTMEcommerceValues,
  SaveForLaterEvent,
  GARemoveCartItem,
  AddToCartGAData,
  RemoveFromCartEvent,
  GA4CommonCartEventPayload,
  CurrentStagedMaterial,
  ScanNowMaterial,
  GA4CheckoutStayReturnPayload,
  GAMapProducts,
  GTMEcommerceItem,
  LegacyUAFields,
  SavedCartproducts,
  EmproveAddToCartData,
} from '@utils/analytics/types'

import {
  RemoveFromCartPagesEnum,
  Dimension91,
  RemoveFromCartEnum,
  AddToCartPagesEnum,
  ExtendedCategoryValues,
  ExtendedEventValues,
  AffiliationCheckoutEventActionEnum,
} from '@utils/analytics/enums'

import {
  showGTMTag,
  isTealium,
  sendEvent,
  legacySendEvent,
  getCurrencyCode,
  regexForStripEncodingUnicode,
  regexForStripHTML,
  DEFAULT_CURRENCY_CODE,
  CART_DATA_STORAGE_KEY,
  ADD_TO_CART_GA_DATA_STORAGE_KEY,
  regexForStripSymbols,
  getCurrencyCodeFromProduct,
  setMaterialData,
  setPaClipData,
  mapPaymentMethod,
  clubbingUpProducts,
  uaConfiguratorAddToCart,
  regexForStripSpecialChars,
  priceFormatter,
} from '@utils/analytics/coreAnalytics'

import {
  sendAddItemsToCartEvent,
  sendTealiumCartDataEvent,
  mapProductErpType,
  getErpTypeParam,
} from '../tealiumAnalytics'

import { calculateDiscountPercentage } from '@utils/analytics/analyticsUtils'
import { ConfiguratorProductType } from '@utils/analytics/configuratorAnalytics'

import {
  CartQuery,
  CartFragment,
  CartItemFragment,
} from '@src/queries/CartQuery.generated'
import { SavedCartItemFragment } from '@src/queries/SavedCartQuery.generated'
import { SavedForLaterItemFragment } from '@src/queries/SaveForLater.generated'
import { OrderItemFragment } from '@src/queries/OrderDetailsQuery.generated'
import { QuickCartItemFragment } from '@src/queries/AllCartsQuery.generated'
import { QuoteItemFragment } from '@src/queries/QuoteDetailsQuery.generated'
import {
  ValidMaterialPricingDetailFragment,
  ProductPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'

import {
  CartItemType,
  CartItemInput,
  PromotionalBundleItemPricing,
} from '@src/types/graphql-types'
import { DynamicProductFragment } from '@src/fragments/DynamicProduct.generated'
import { ReactElement } from 'react'

export const GTMMarketplaceEventCategory = {
  ...GTMEventCategory,
  ...ExtendedCategoryValues,
}

export const GTMMarketplaceEventValues = {
  ...EventValues,
  ...ExtendedEventValues,
}

export const sendAddToCartAnalyticsEvent = (
  gtmFlag?: boolean,
  overrides?: {
    action?: string
    category?: string
    label?: string
  }
): void => {
  if (isTealium && !gtmFlag) {
    sendAddItemsToCartEvent({
      sessionStorageKey: ADD_TO_CART_GA_DATA_STORAGE_KEY,
    })
  }

  if (showGTMTag) {
    try {
      const sessionStorageData = sessionStorage.getItem(
        ADD_TO_CART_GA_DATA_STORAGE_KEY
      ) as AddToCartGAData
      // Return early if no data is found
      if (!sessionStorageData) {
        console.warn(
          'No data found in sessionStorage for key:',
          ADD_TO_CART_GA_DATA_STORAGE_KEY
        )
        return
      }
      const { products, pageName, currency } = sessionStorageData

      if (products && products.length) {
        // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
        //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
        legacySendEvent({
          eventType: GTMEventTypes.AddToCart,
          payload: {
            eventCategory:
              overrides?.category || GTMEventCategory.AddToCart.toLowerCase(),
            eventAction: overrides?.action || pageName,
            eventLabel:
              overrides?.label || products?.map((item) => item?.id).join('|'),
            eventInteractionType: 0,
            ecommerce: {
              currencyCode: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
              add: {
                actionField: {
                  list: pageName.toLowerCase(),
                },
                products: products,
              },
            },
          },
        })

        // GA4 - Add To Cart
        legacySendEvent({
          payload: {
            event: 'add_to_cart',
            addtocart_type: 'default',
            ecommerce: {
              currency: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
              value: products.reduce(
                (acc, product) =>
                  (Number(product.price) || 1) * (product.quantity ?? 1) + acc,
                0
              ),
              items: products.map((product) => ({
                item_id: product.id,
                item_name: product.name,
                discount: product.discount ?? 0,
                item_brand: product.brand,
                item_list_id: pageName?.toLowerCase(),
                item_list_name: pageName?.toLowerCase(),
                item_variant: product.variant,
                price: priceFormatter(product?.price),
                item_type: product.dimension91,
                quantity: product.quantity ?? 1,
              })),
            },
          },
        })
      }
      sessionStorage.removeItem(ADD_TO_CART_GA_DATA_STORAGE_KEY)
    } catch (error) {
      sessionStorage.removeItem(ADD_TO_CART_GA_DATA_STORAGE_KEY)
      console.error('ERROR - Add to cart Event ', error)
    }
  }
}

export const sendBuyItNowAddToCartEvent = ({
  products,
  pageName,
  isMarketplace,
}: {
  products: CartQuery['getCart']
  pageName: string | undefined
  isMarketplace: boolean | undefined
}): void => {
  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'buy it now',
      ecommerce: {
        currency: getCurrencyCode(products.currency || DEFAULT_CURRENCY_CODE),
        value: products.netTotal,
        items: products.items?.map((product) => ({
          item_id: product.material.product.toLowerCase(),
          item_name: product.material.name
            ?.replace(regexForStripSymbols, '')
            .replace(regexForStripHTML, '')
            .replace(regexForStripEncodingUnicode, '')
            .toLowerCase(),
          discount:
            calculateDiscountPercentage(
              product.pricing?.listPrice,
              product.pricing?.price
            ) || 0,
          item_list_id: pageName?.toLowerCase(),
          item_list_name: pageName?.toLowerCase(),
          item_brand: product.brand?.toLowerCase(),
          item_variant: product.material.number.toLowerCase(),
          item_type: isMarketplace ? 'marketplace' : 'standard',
          price: priceFormatter(product.pricing?.price),
          quantity: product.quantity,
        })),
      },
    },
  })
}

export const setSavedCartData = (
  items: SavedCartItemFragment[],
  pageName: AddToCartPagesEnum,
  userType?: Object,
  cartType?: string
): SavedCartproducts[] => {
  try {
    const itemGroups = groupBy(items || [], (item) => item.materialId)
    const currency =
      items?.length && items[0]?.currency
        ? items[0]?.currency
        : DEFAULT_CURRENCY_CODE
    const products = Object.values(itemGroups).map((group) => {
      const item = group[0]
      const avgGroupPrice =
        group.reduce((acc, item) => (item?.price || 0) + acc, 0) / group.length
      return {
        id: (
          item?.product ||
          item?.materialId ||
          EventValues.Empty
        ).toLowerCase(),
        name:
          (item?.materialDescription || item?.materialName)
            ?.replace(regexForStripEncodingUnicode, '')
            ?.replace(regexForStripHTML, '')
            ?.replace(regexForStripSymbols, '')
            ?.toLowerCase() || EventValues.Empty,
        dimension91:
          cartType === 'marketplacecart' ? 'marketplace' : 'standard',
        variant: (item?.materialId || EventValues.Empty).toLowerCase(),
        brand: (item?.brand || EventValues.Empty).toLowerCase(),
        quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
        price: priceFormatter(avgGroupPrice),
        ...(!showGTMTag && {
          erpType: mapProductErpType(
            (item?.erpType as string[]) || [],
            userType,
            pageName
          ),
        }),
      }
    })
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products,
      pageName,
      currency,
    })
    sendAddToCartAnalyticsEvent()
    return products ? products : []
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
    return []
  }
}

export const sendRemoveFromCartEvent = ({
  productIds,
  actionTitle,
  ecommerce,
}: RemoveFromCartEvent): void => {
  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    eventType: GTMEventTypes.RemoveFromCart.toLowerCase(),
    payload: {
      eventCategory: GTMEventCategory.RemoveFromCart.toLowerCase(),
      eventAction: actionTitle,
      eventLabel: productIds,
      eventInteractionType: 0,
      ecommerce,
    },
  })
}

export const handleRemoveItemsForGA = (
  payload,
  marketplaceCartUrlType: boolean
): void => {
  const deletedItems: any = []
  Object.keys(payload || {}).forEach((id) => {
    const cartItem = payload[id]
    if (cartItem.markedForDelete) {
      deletedItems.push(cartItem)
    }
  })
  if (deletedItems.length) {
    const itemGroups = groupBy(deletedItems || [], (item) =>
      item.productType === EventValues.Emprove
        ? item?.dossierMaterialNumber
        : item.materialNumber
    )
    const gaPayload = {
      productIds:
        Object.values(itemGroups)
          .map((product) => product[0]?.productId?.toLowerCase())
          .join('|') || EventValues.Empty,
      actionTitle: EventValues.ShoppingCart.toLowerCase(),
      ecommerce: {
        currencyCode: getCurrencyCode(
          deletedItems[0]?.currency || DEFAULT_CURRENCY_CODE
        ), //default value should be USD in case of null.
        remove: {
          actionField: {
            list: EventValues.ShoppingCart.toLowerCase(),
          },
          products: Object.values(itemGroups).map((group) => {
            const item = group[0]
            const avgGroupPrice =
              group.reduce((acc, item) => (item.price || 0) + acc, 0) /
              group.length
            return {
              id: item?.productId?.toLowerCase() || EventValues.Empty,
              name:
                item?.name
                  ?.replace(regexForStripEncodingUnicode, '')
                  ?.replace(regexForStripHTML, '')
                  ?.toLowerCase() || EventValues.Empty,
              variant:
                (item.productType === EventValues.Emprove
                  ? item?.dossierMaterialNumber
                  : item?.materialNumber
                )?.toLowerCase() || EventValues.Empty,
              brand: item?.brand?.toLowerCase() || EventValues.Empty,

              quantity:
                group.reduce((acc, item) => acc + item.quantity, 0) || 1,
              price: priceFormatter(avgGroupPrice),
              dimension91: marketplaceCartUrlType ? 'marketplace' : 'standard',
            }
          }),
        },
      },
    }

    sendRemoveFromCartEvent(gaPayload)

    // GA4  remove_from_cart event

    const ecommerce = {
      currency: getCurrencyCode(
        deletedItems[0]?.currency || DEFAULT_CURRENCY_CODE
      ),
      items: Object.values(itemGroups).map((group) => {
        const item = group[0]
        const avgGroupPrice =
          group.reduce((acc, item) => (item.price || 0) + acc, 0) / group.length
        return {
          item_id: item?.productId?.toLowerCase() || EventValues.Empty,
          item_name:
            item?.name
              ?.replace(regexForStripEncodingUnicode, '')
              ?.replace(regexForStripHTML, '')
              ?.toLowerCase() || EventValues.Empty,
          item_variant:
            (item.productType === EventValues.Emprove
              ? item?.dossierMaterialNumber
              : item?.materialNumber
            )?.toLowerCase() || EventValues.Empty,
          item_brand: item?.brand?.toLowerCase() || EventValues.Empty,
          quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
          price: priceFormatter(avgGroupPrice),
          item_type: marketplaceCartUrlType ? 'marketplace' : 'standard',
          item_list_id: RemoveFromCartEnum.ShoppingCart,
          item_list_name: RemoveFromCartEnum.ShoppingCart,
        }
      }),
    }
    legacySendEvent({
      payload: {
        event: 'remove_from_cart',
        addtocart_type: 'default',
        ecommerce,
      },
    })
  }
}

export const sendRemoveBuyNow = (): void => {
  const payload: GTMEcommerceValues | null =
    sessionStorage.getItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY)

  const product = payload?.ecommerce.purchase.products[0]
  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'buy it now',
      ecommerce: {
        currency: payload?.ecommerce.currencyCode,
        items: [
          {
            item_id: product?.id,
            item_name: product?.name
              .replace(regexForStripEncodingUnicode, '')
              .replace(regexForStripHTML, '')
              .replace(regexForStripSymbols, ''),
            item_brand: product?.brand,
            item_list_id: 'slider',
            item_list_name: 'slider',
            item_variant: product?.variant,
            price: Number(product?.price),
            item_type: product?.dimension91,
            quantity: product?.quantity,
          },
        ],
      },
    },
  })
}

export const useStoreCartDataForAnalytics = (queryData?: CartQuery): void => {
  const router = useRouter()
  if (router.asPath.includes('order_acknowledgement') || !queryData) return

  const data = queryData?.getCart
  const shippingMethod =
    data?.shippingPreference?.participantShippingMethods?.find(
      (method) =>
        method?.shippingMethodCode === data?.shippingPreference?.shippingMethod
    )?.description
  const itemGroups = groupBy(data?.items || [], (item) => item.material.id)

  return sessionStorage.setItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY, {
    eventCategory: GTMEventCategory.OrderConfirmation,
    eventAction: data?.id || EventValues.Empty,
    eventLabel:
      Object.values(itemGroups)
        .map((group) =>
          (group[0]?.type === 'Configurator'
            ? group[0]?.material?.number || EventValues.Empty
            : group[0]?.material?.product || EventValues.Empty
          ).toLowerCase()
        )
        .join('|') || EventValues.Empty,
    purchaseSoldToNumber: data?.partners.soldTo || EventValues.Empty,
    purchaseBillToNumber: data?.partners?.billTo || EventValues.Empty,
    purchaseShipToNumber: data?.partners?.shipTo || EventValues.Empty,
    purchaseParticipantId:
      sessionStorage.getItem('PARTICIPANT_ID') || EventValues.Empty,
    eventInteractionType: 1,
    ecommerce: {
      currencyCode: getCurrencyCode(data?.currency || DEFAULT_CURRENCY_CODE),
      purchase: {
        actionField: {
          id: data?.id || EventValues.Empty,
          tax: priceFormatter(data?.totals?.tax),
          shipping: priceFormatter(data?.totals?.shipping),
          revenue: priceFormatter(data?.totals?.total),
          coupon: EventValues.Empty,
          shippingMethod: shippingMethod,
        },
        products: Object.values(itemGroups).map((group) => {
          const item = group[0]
          const avgGroupPrice =
            group.reduce((acc, item) => (item.pricing?.price || 0) + acc, 0) /
            group.length
          return {
            id:
              (item.type === 'Configurator'
                ? item.material.number
                : item.material.product
              )?.toLowerCase() || EventValues.Empty,
            name:
              (item.type === 'Configurator'
                ? item.material?.description || item.material?.name
                : item.material?.name || item.material?.description
              )?.toLowerCase() || EventValues.Empty,
            variant: item.material.number?.toLowerCase() || EventValues.Empty,
            brand: (item?.type === 'Configurator'
              ? item?.material?.brand?.name || EventValues.Empty
              : item?.material?.brand?.key || EventValues.Empty
            )?.toLowerCase(),
            quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
            price: priceFormatter(avgGroupPrice),
            dimension91: 'standard',
            coupon: item.promoCode || EventValues.Empty,
          }
        }),
      },
    },
  })
}

export const sendOrderConfirmationEvent = (payload): void => {
  try {
    if (!payload || !showGTMTag) return
    const nonBundleItems = payload?.items?.filter(
      (item) => item?.type !== 'PromotionalBundle'
    )

    const itemGroups = groupBy(nonBundleItems || [], (item) =>
      item?.type === EventValues.Emprove
        ? item?.emproveDossierInfo?.dossierMaterialNumber
        : item?.material?.id
    )

    const nonBundleproducts = Object.values(itemGroups).map((group) => {
      const item = group[0]
      const avgGroupPrice =
        group.reduce(
          (acc, item) => (item?.pricePerUnit || item?.listPrice || 0) + acc,
          0
        ) / group.length

      const discount = calculateDiscountPercentage(
        item?.listPrice,
        item?.pricePerUnit
      )

      if (item.type === EventValues.Emprove) {
        return {
          id: item?.material?.id?.toLowerCase() || EventValues.Empty,
          name:
            item?.material?.description
              ?.replace(regexForStripEncodingUnicode, '')
              ?.replace(regexForStripHTML, '')
              ?.toLowerCase() ||
            item?.material?.name
              ?.replace(regexForStripEncodingUnicode, '')
              ?.replace(regexForStripHTML, '')
              ?.toLowerCase() ||
            EventValues.Empty,
          variant:
            item?.emproveDossierInfo?.dossierMaterialNumber?.toLowerCase() ||
            EventValues.Empty,
          brand:
            item?.material?.brand?.name?.toLowerCase() || EventValues.SialBrand,
          quantity: 1,
          price: priceFormatter(avgGroupPrice),
          coupon: item?.promoCode?.toLowerCase() || undefined,
          discount: discount,
          dimension91:
            payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
        }
      } else {
        return {
          id: (item?.type === ConfiguratorProductType.Configurator
            ? item?.material?.number || EventValues.Empty
            : item?.material?.product || EventValues.Empty
          )?.toLowerCase(),
          name:
            (item?.type === ConfiguratorProductType.Configurator
              ? item.material?.description || item.material?.name
              : item.material?.name || item.material?.description
            )
              ?.replace(regexForStripHTML, '')
              ?.replace(regexForStripEncodingUnicode, '')
              ?.toLowerCase() || EventValues.Empty,
          variant: item?.material?.number?.toLowerCase() || EventValues.Empty,
          brand: (item?.type === ConfiguratorProductType.Configurator
            ? item?.material?.brand?.name || EventValues.Empty
            : item?.material?.brand?.key || EventValues.Empty
          )?.toLowerCase(),

          quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
          price: priceFormatter(avgGroupPrice),
          coupon: item?.promoCode?.toLowerCase() || undefined,
          discount: discount,
          dimension91:
            payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
        }
      }
    })

    const bundleItems = payload?.items?.filter(
      (item) => item?.type === 'PromotionalBundle'
    )

    let promoProducts: any = []
    if (bundleItems.length) {
      const promoItems: any = groupBy(
        bundleItems || [],
        (item) => item?.material?.id
      )
      forEach(promoItems, (_value, key) => {
        promoItems[key] = groupBy(
          promoItems[key] || [],
          (item) => item?.promoCode
        )
      })

      promoProducts = Object.values(promoItems).map((group: any) => {
        return Object.values(group).map((group1: any) => {
          const item = group1[0]
          const avgGroupPrice =
            group1.reduce((acc, item) => (item?.pricePerUnit || 0) + acc, 0) /
            group1.length

          return {
            id: (item?.material?.product || EventValues.Empty)?.toLowerCase(),
            name:
              (item.material?.name || item.material?.description)
                ?.replace(regexForStripHTML, '')
                ?.replace(regexForStripEncodingUnicode, '')
                ?.toLowerCase() || EventValues.Empty,
            variant: item?.material?.number?.toLowerCase() || EventValues.Empty,
            brand: (
              item?.material?.brand?.key || EventValues.Empty
            )?.toLowerCase(),
            quantity:
              group1.reduce((acc, item) => acc + item?.quantity, 0) || 1,
            price: priceFormatter(avgGroupPrice),
            coupon: item?.promoCode?.toLowerCase() || undefined,
            discount: calculateDiscountPercentage(
              item?.listPrice,
              item?.pricePerUnit
            ),
            dimension91:
              payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
          }
        })
      })
    }

    const flattenedProd = (promoProducts.length && promoProducts?.flat(1)) || []

    const packageHandlingKeys = [
      'ice',
      'electricalFee',
      'eurVolatileMassCharge',
      'dutyTotal',
      'pkg',
    ]
    const packageHandlingCharges = packageHandlingKeys.reduce(
      (total, key) => total + (payload?.[key] ?? 0),
      0
    )

    const ecommerce = {
      currencyCode: getCurrencyCode(payload?.currency || DEFAULT_CURRENCY_CODE),
      purchase: {
        actionField: {
          id: payload?.orderNumber || payload?.orderId || EventValues.Empty,
          tax: priceFormatter(payload?.salesTax),
          shipping: priceFormatter(payload?.transHandlingCharge),
          revenue: payload?.total?.toString() || '0.00',
          coupon: undefined,
        },
        products: [...nonBundleproducts, ...flattenedProd],
      },
    }

    const paymentMethod = mapPaymentMethod(
      payload.paymentInfo.paymentMethod,
      payload.chargebackCodes.length > 0,
      payload.contractNumber
    )

    const subTotal = payload?.items?.reduce(
      (acc, item) => (item.pricePerUnit * item.quantity || 0) + acc,
      0
    )

    // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
    //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
    legacySendEvent({
      eventType: GTMEventTypes.PurchaseConfirmation,
      payload: {
        eventCategory: GTMEventCategory.OrderConfirmation.toLowerCase(),
        deepOrderID: payload?.readableOrderId || EventValues.Empty,
        eventAction:
          payload?.orderNumber || payload?.orderId || EventValues.Empty,
        eventLabel:
          [
            ...Object.values(itemGroups).map((group) =>
              group[0].type === EventValues.Emprove
                ? group[0]?.material?.id?.toLowerCase()
                : group[0].type === ConfiguratorProductType.Configurator
                  ? group[0]?.material?.number?.toLowerCase()
                  : group[0]?.material?.product?.toLowerCase()
            ),
            ...flattenedProd.map((item) => item?.id),
          ].join('|') || EventValues.Empty,
        purchaseSoldToNumber: payload?.soldTo || EventValues.Empty,
        purchaseShipToNumber:
          payload?.shipTo?.partnerNumber ||
          payload?.shipTo?.customerNumber ||
          EventValues.NotAvailable,
        purchaseBillToNumber:
          payload?.billTo?.partnerNumber ||
          payload?.billTo?.customerNumber ||
          EventValues.NotAvailable,
        purchaseParticipantId:
          sessionStorage.getItem('PARTICIPANT_ID') || EventValues.NotAvailable,
        paymentMethod,
        eventInteractionType: 1,
        ecommerce,
        orderType:
          payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
      },
    })

    // GA4 - Order Confirmation
    legacySendEvent({
      payload: {
        event: 'purchase',
        addtocart_type:
          payload.cartType === 'buyitnowcart' ? 'buy it now' : 'default',
        order_type:
          payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
        deep_order_id: payload?.readableOrderId || EventValues.Empty,
        order_participant_id:
          sessionStorage.getItem('PARTICIPANT_ID') || EventValues.NotAvailable,
        order_sold_to: payload?.soldTo || EventValues.Empty,
        order_bill_to:
          payload?.billTo?.partnerNumber ||
          payload?.billTo?.customerNumber ||
          EventValues.NotAvailable,
        order_ship_to:
          payload?.shipTo?.partnerNumber ||
          payload?.shipTo?.customerNumber ||
          EventValues.NotAvailable,
        payment_type: paymentMethod,
        package_handling: priceFormatter(packageHandlingCharges),
        ecommerce: {
          transaction_id:
            payload?.orderNumber || payload?.orderId || EventValues.Empty,
          value: subTotal || 1,
          tax: priceFormatter(payload?.salesTax),
          shipping: priceFormatter(payload?.transHandlingCharge),
          coupon: undefined,
          currency: getCurrencyCode(payload?.currency || DEFAULT_CURRENCY_CODE),
          items: ecommerce?.purchase?.products?.map((product) => ({
            item_id: product.id,
            item_name: product.name,
            coupon: product.coupon,
            discount: product.discount,
            item_brand: product.brand,
            item_variant: product.variant,
            price: priceFormatter(product?.price),
            item_type: product.dimension91,
            quantity: product.quantity ?? 1,
          })),
        },
      },
    })
  } catch (error) {
    console.error('ERROR - Sending Order Confirmation Event: ', error)
  }
}

export const sendSaveForLaterEvent = (
  { action, label }: SaveForLaterEvent,
  {
    clickType = 'page',
    clickAction,
    clickDetails,
    isMarketplaceCart,
    component = 'grid',
    elementType,
    linkText,
    linkUrl,
  }: {
    clickType?: string
    clickAction?: string
    clickDetails?: string | undefined
    isMarketplaceCart?: boolean
    component?: string
    elementType?: string
    linkText?: string
    linkUrl?: string | undefined
  } = {}
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'saved_for_later_clicks',
      click_type: `saved for later : ${
        component === 'grid' ? component : clickType
      }`,
      click_action: clickAction || action,
      click_details: clickDetails,
      cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      component: component || undefined,
      element_type: elementType || undefined,
      link_text: linkText || action || undefined,
      link_url: linkUrl,
      //Legacy UA event data
      eventCategory: 'save for later',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

// Updated GA4 save for later interaction event for shopping cart page for save for later,delete and view all link
export const sendSaveForLaterInteractionEvent = (
  isMarketplaceCart: boolean,
  ga4payload: GA4CommonCartEventPayload,
  item?: CartItemFragment | SavedForLaterItemFragment
): void => {
  const cartType = isMarketplaceCart ? 'marketplace' : 'standard'
  const productId = item?.material?.product?.toLowerCase() || undefined
  const productBrand = item?.material?.brand.key.toLowerCase() || undefined
  const productVariant = item?.material?.number?.toLowerCase() || undefined

  const productName =
    item?.material && 'name' in item.material && item?.material?.name
      ? item?.material?.name
          .replace(regexForStripEncodingUnicode, '')
          .replace(regexForStripHTML, '')
          .toLowerCase()
      : undefined

  const basePayload = {
    event: 'save_for_later_interaction',
    action: ga4payload?.action,
    detail: undefined,
    section: ga4payload?.section,
    component: 'body',
    element_type: 'link',
    element_text: ga4payload?.elementText,
    link_url: undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    product_id: productId,
    product_brand: productBrand,
    product_variant: productVariant,
    product_name: productName,
    cart_type: cartType,
  }
  legacySendEvent({
    payload: basePayload,
  })
}

export const sendAddToSaveForLaterEcommerceEvent = (
  item,
  isMarketplaceCart: boolean
): void => {
  const ecommerce = {
    currency: item.currency || DEFAULT_CURRENCY_CODE,
    items: [
      {
        item_id: item?.material?.product?.toLowerCase(),
        item_name:
          item?.material?.name
            .replace(regexForStripEncodingUnicode, '')
            .replace(regexForStripHTML, '')
            .toLowerCase() || EventValues.Empty,
        item_variant: item?.material?.number?.toLowerCase(),
        item_brand:
          item.material.brand.key.toLowerCase() || EventValues.NotAvailable,
        quantity: item.quantity || 1,
        price: priceFormatter(item?.pricing?.price),
        item_type: isMarketplaceCart ? 'marketplace' : 'standard',
        item_list_id: 'saved_for_later',
        item_list_name: 'saved_for_later',
      },
    ],
  }

  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'default',
      ecommerce,
    },
  })
}

export const sendAddToCartFromSaveForLaterEcommerceEvent = (
  item,
  isMarketplaceCart: boolean
): void => {
  const ecommerce = {
    currency: item.currency || DEFAULT_CURRENCY_CODE,
    value: 1,
    items: [
      {
        item_id: item?.material?.product?.toLowerCase(),
        item_name:
          item.material.description
            .replace(regexForStripEncodingUnicode, '')
            .replace(regexForStripHTML, '')
            .toLowerCase() || EventValues.Empty,
        discount: 0,
        item_variant: item?.material?.number?.toLowerCase(),
        item_brand:
          item.material.brand.key.toLowerCase() || EventValues.NotAvailable,
        quantity: 1,
        price: 1,
        item_type: isMarketplaceCart ? 'marketplace' : 'standard',
        item_list_id: 'saved_for_later',
        item_list_name: 'saved_for_later',
      },
    ],
  }

  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce,
    },
  })
}

export const handleGARemoveFromCart = (
  items: GARemoveCartItem[],
  cartPage: RemoveFromCartPagesEnum | EventValues | string,
  marketplacecart?: string,
  currency?: string
): void => {
  const itemGroups = groupBy(items || [], (item) =>
    item?.type === CartItemType.Emprove
      ? item?.emproveDossierInfo?.dossierMaterialNumber
      : item.material.id
  )

  const cartPayload = {
    productIds:
      Object.values(itemGroups)
        .map((group) =>
          group[0].type === CartItemType.Emprove
            ? group[0]?.material?.id?.toLowerCase()
            : group[0].type === CartItemType.Configurator
              ? group[0]?.material?.number?.toLowerCase()
              : group[0]?.material?.product?.toLowerCase()
        )
        .join('|') || EventValues.Empty,
    actionTitle: cartPage?.toLowerCase(),
    ecommerce: {
      currencyCode:
        items && getCurrencyCode(items[0]?.currency || DEFAULT_CURRENCY_CODE),
      remove: {
        actionField: {},
        products:
          Object.values(itemGroups).map((group) => {
            const item = group[0]
            const avgGroupPrice =
              group.reduce((acc, item) => (item.pricing?.price || 0) + acc, 0) /
              group.length
            if (item.type === CartItemType.Emprove) {
              return {
                id: item?.material?.id?.toLowerCase() || EventValues.Empty,
                name:
                  item?.material?.description
                    ?.replace(regexForStripEncodingUnicode, '')
                    ?.replace(regexForStripHTML, '')
                    ?.toLowerCase() ||
                  item?.material?.name
                    ?.replace(regexForStripEncodingUnicode, '')
                    ?.replace(regexForStripHTML, '')
                    ?.toLowerCase() ||
                  EventValues.Empty,
                variant:
                  item?.emproveDossierInfo?.dossierMaterialNumber?.toLowerCase() ||
                  EventValues.Empty,
                brand:
                  item?.material?.brand?.name?.toLowerCase() ||
                  EventValues.SialBrand ||
                  EventValues.Empty,
                quantity: 1,
                price:
                  cartPage !== EventValues.Slider
                    ? priceFormatter(avgGroupPrice)
                    : null,
                dimension91: marketplacecart
                  ? Dimension91.Marketplace
                  : Dimension91.Standard,
              }
            } else {
              return {
                id: (item?.type === CartItemType.Configurator
                  ? item.material?.number || EventValues.Empty
                  : item.material?.product || EventValues.Empty
                ).toLowerCase(),
                name: (
                  (item?.type === CartItemType.Configurator
                    ? item.material?.description || item.material?.name
                    : item.material?.name || item.material?.description) ||
                  EventValues.Empty
                )
                  ?.replace(regexForStripHTML, '')
                  ?.replace(regexForStripEncodingUnicode, '')
                  ?.toLowerCase(),
                variant:
                  item?.material?.number?.toLowerCase() || EventValues.Empty,
                brand: (item?.type === CartItemType.Configurator
                  ? item?.material?.brand?.name || EventValues.Empty
                  : item?.material?.brand?.key || EventValues.Empty
                )?.toLowerCase(),
                quantity:
                  group.reduce((acc, item) => acc + item.quantity, 0) || 1,
                price:
                  cartPage !== EventValues.Slider
                    ? priceFormatter(avgGroupPrice)
                    : null,
                dimension91: marketplacecart
                  ? Dimension91.Marketplace
                  : Dimension91.Standard,
              }
            }
          }) || [],
      },
    },
  }
  sendRemoveFromCartEvent(cartPayload)

  // GA4  remove_from_cart event
  const currencyValue = currency ?? items[0]?.currency

  const ecommerce = {
    currency: getCurrencyCode(currencyValue || DEFAULT_CURRENCY_CODE),
    items:
      Object.values(itemGroups).map((group) => {
        const item = group[0]
        const avgGroupPrice =
          group.reduce((acc, item) => (item.pricing?.price || 0) + acc, 0) /
          group.length
        if (item.type === CartItemType.Emprove) {
          return {
            item_id: item?.material?.id?.toLowerCase() || EventValues.Empty,
            item_name:
              item?.material?.description
                ?.replace(regexForStripEncodingUnicode, '')
                ?.replace(regexForStripHTML, '')
                ?.toLowerCase() ||
              item?.material?.name
                ?.replace(regexForStripEncodingUnicode, '')
                ?.replace(regexForStripHTML, '')
                ?.toLowerCase() ||
              EventValues.Empty,
            item_variant:
              item?.emproveDossierInfo?.dossierMaterialNumber?.toLowerCase() ||
              EventValues.Empty,
            item_brand:
              item?.material?.brand?.name?.toLowerCase() ||
              EventValues.SialBrand ||
              EventValues.Empty,
            item_list_id: cartPage?.toLowerCase(),
            item_list_name: cartPage?.toLowerCase(),
            quantity: 1,
            price:
              cartPage !== EventValues.Slider
                ? priceFormatter(avgGroupPrice)
                : null,
            item_type: marketplacecart ? 'marketplace' : 'standard',
          }
        } else {
          return {
            item_id: (item?.type === CartItemType.Configurator
              ? item.material?.number || EventValues.Empty
              : item.material?.product || EventValues.Empty
            ).toLowerCase(),
            item_name: (
              (item?.type === CartItemType.Configurator
                ? item.material?.description || item.material?.name
                : item.material?.name || item.material?.description) ||
              EventValues.Empty
            )
              ?.replace(regexForStripHTML, '')
              ?.replace(regexForStripEncodingUnicode, '')
              ?.toLowerCase(),
            item_variant:
              item?.material?.number?.toLowerCase() || EventValues.Empty,
            item_brand: (item?.type === CartItemType.Configurator
              ? item?.material?.brand?.name || EventValues.Empty
              : item?.material?.brand?.key || EventValues.Empty
            )?.toLowerCase(),
            item_list_id: cartPage?.toLowerCase(),
            item_list_name: cartPage?.toLowerCase(),
            quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
            price:
              cartPage !== EventValues.Slider
                ? priceFormatter(avgGroupPrice)
                : null,
            item_type: marketplacecart ? 'marketplace' : 'standard',
          }
        }
      }) || [],
  }

  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'default',
      ecommerce,
    },
  })
}

export const setEmproveAddToCartData = (
  items,
  pageName: AddToCartPagesEnum
): EmproveAddToCartData[] => {
  try {
    const currency =
      items?.length && items[0]?.currency
        ? items[0]?.currency
        : DEFAULT_CURRENCY_CODE
    const itemGroups = groupBy(items || [], (item) => item.materialNumber)
    const products = Object.values(itemGroups).map((group) => {
      const item = group[0]
      return {
        id: (item?.materialNumber || EventValues.Empty).toLowerCase(),
        name:
          item?.materialDescription
            ?.replace(regexForStripEncodingUnicode, '')
            ?.replace(regexForStripHTML, '')
            ?.replace(regexForStripSymbols, '')
            ?.toLowerCase() || EventValues.Empty,
        variant: (
          item?.dossierMaterialNumber || EventValues.Empty
        ).toLowerCase(),
        brand: EventValues.SialBrand,
        quantity: 1,
        price: priceFormatter(item?.pricing),
        dimension91: 'standard',
      }
    })
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products,
      pageName,
      currency,
    })
    sendAddToCartAnalyticsEvent(true)
    return products ? products : []
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
    return []
  }
}

export const setQuickOrderAddToCartData = (
  pricingForMultipleProductInput: ProductPricingDetailFragment[],
  pageName: AddToCartPagesEnum,
  erpType?: Object
): void => {
  try {
    const itemGroups = groupBy(
      pricingForMultipleProductInput || [],
      (item) => item.productNumber
    )
    const currency =
      pricingForMultipleProductInput?.length &&
      pricingForMultipleProductInput[0]?.materialPricing[0]?.currency
        ? pricingForMultipleProductInput[0]?.materialPricing[0]?.currency
        : DEFAULT_CURRENCY_CODE

    const products = Object.values(itemGroups).map((group) => {
      const item = group[0]
      const materialItem = item?.materialPricing[0]
      const avgGroupPrice =
        group.reduce(
          (acc, item) => (item?.materialPricing[0]?.price || 0) + acc,
          0
        ) / group.length
      return {
        id: (materialItem?.product || EventValues.Empty)?.toLowerCase(),
        name:
          materialItem?.materialDescription
            ?.replace(regexForStripEncodingUnicode, '')
            ?.replace(regexForStripHTML, '')
            ?.replace(regexForStripSymbols, '')
            ?.toLowerCase() || EventValues.Empty,
        variant: (
          materialItem?.materialNumber || EventValues.Empty
        )?.toLowerCase(),
        brand: (materialItem?.brand || EventValues.Empty)?.toLowerCase(),
        quantity:
          group.reduce(
            (acc, item) => acc + item?.materialPricing[0]?.quantity,
            0
          ) || 1,
        price: priceFormatter(avgGroupPrice),
        dimension91:
          materialItem?.catalogType === 'marketplace'
            ? 'marketplace'
            : 'standard',
        ...(!showGTMTag && {
          erpType:
            erpType &&
            mapProductErpType(erpType[materialItem?.materialNumber] || []),
        }),
      }
    })
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products,
      pageName,
      currency,
    })
    sendAddToCartAnalyticsEvent()
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
  }
}

export const sendShoppingCartPageEvent = ({
  actionTitle,
  gaId,
  clickType = 'shopping cart',
  clickAction,
  clickDetails,
  isMarketplaceCart = false,
  component,
  elementType,
  linkText,
  linkUrl,
}: {
  actionTitle: string
  gaId: string | undefined
  clickType?: string
  clickAction?: string
  clickDetails?: string | undefined
  isMarketplaceCart?: boolean
  component: string
  elementType: string
  linkText?: string
  linkUrl?: string | undefined
}): void => {
  // GA4
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: actionTitle === 'Download Cart' ? 'file_download' : 'ga4Event',
      ...(actionTitle === 'Download Cart' && {
        file_extension: 'pdf',
        file_name: 'milliporesigmacart',
        file_category: 'cart',
        product_id: undefined,
        lot_number: undefined,
        error_description: undefined,
      }),
      ...(actionTitle !== 'Download Cart' && {
        event_name: 'shopping_cart_page_click',
        click_type: clickType || undefined,
        click_action: clickAction || actionTitle.toLowerCase() || undefined,
        click_details: clickDetails,
        shoppping_cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      }),
      component,
      element_type: elementType,
      link_text:
        linkText || clickDetails || actionTitle.toLowerCase() || undefined,
      link_url: linkUrl,
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0,
    },
  })
}

export const sendProceedToCheckoutEvent = ({
  actionTitle,
  gaId,
  isMarketplaceCart,
  section,
  component,
  switchCart = false,
}: {
  actionTitle: string
  gaId: string | undefined
  isMarketplaceCart: boolean
  section: string
  component: string
  switchCart?: boolean
}): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      action: undefined,
      detail: `proceed to ${
        isMarketplaceCart ? 'marketplace' : 'standard'
      } checkout`,
      section,
      component,
      element_type: 'button',
      element_text: 'checkout',
      link_url: undefined,
      core_event: 'yes',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      event: 'proceed_to_checkout',
      cart_type: isMarketplaceCart && !switchCart ? 'marketplace' : 'standard',
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0,
    },
  })
}

export const sendBeginSaveCartEvent = ({
  actionTitle,
  gaId,
  isMarketplaceCart,
}: {
  actionTitle: string
  gaId: string | undefined
  isMarketplaceCart: boolean
}): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      action: undefined,
      detail: `proceed to save ${
        isMarketplaceCart ? 'marketplace' : 'standard'
      } cart`,
      section: 'summary',
      component: 'right rail',
      element_type: 'button',
      element_text: 'save cart',
      link_url: undefined,
      core_event: 'yes',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      event: 'begin_save_cart',
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0,
    },
  })
}

export const sendViewShoppingCartEvent = (
  {
    isMarketplaceCart,
    detail,
    section,
    component,
    linkUrl,
  }: {
    isMarketplaceCart: boolean
    detail: string
    section: string
    component: string
    linkUrl?: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'view_shopping_cart',
    action: undefined,
    detail,
    section,
    component,
    element_type: 'button',
    element_text: 'view cart',
    link_url: linkUrl,
    core_event: 'yes',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

/*
'checkout page: summary'
*/
export const sendCheckoutPageEvent = (
  {
    actionTitle,
    gaId,
  }: { actionTitle?: string | undefined; gaId?: string | undefined },
  isMarketplace?: boolean,
  ga4payload?: GA4CommonCartEventPayload
): void => {
  const basePayload = {
    event: ga4payload?.event || '',
    action: ga4payload?.action,
    detail: ga4payload?.detail,
    section: ga4payload?.section,
    component: ga4payload?.component,
    element_type: ga4payload?.elementType,
    element_text: ga4payload?.elementText,
    link_url: ga4payload?.linkUrl,
    core_event: ga4payload?.coreEvent,
    user_detail: ga4payload?.userDetail,
    event_group: ga4payload?.eventGroup,
    event_subgroup: ga4payload?.eventSubGroup,
    cart_type: ga4payload?.cartType,
  }

  const legacyUAFields = {
    eventCategory: isMarketplace
      ? GTMMarketplaceEventCategory.MarketplaceCheckoutPage
      : GTMEventCategory.CheckoutPage,
    eventAction: isMarketplace
      ? GTMMarketplaceEventValues.SubmitMarketplaceOrder
      : actionTitle,
    eventLabel: gaId,
    eventInteractionType: 0,
  }

  const buildPayload = ga4payload
    ? actionTitle
      ? {
          eventType: GTMEventTypes.AnalyticsEvent,
          payload: { ...basePayload, ...legacyUAFields },
        }
      : { payload: basePayload }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: legacyUAFields,
      }

  sendEvent(buildPayload)
}

export const sendCheckoutStayReturnEvent = (
  ga4payload?: GA4CheckoutStayReturnPayload,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4payload?.eventType,
    action: undefined,
    cart_type: ga4payload?.isMarketplace ? 'marketplace' : 'standard',
    component: ga4payload?.component,
    detail: undefined,
    element_type: ga4payload?.elementType,
    element_text: ga4payload?.elementText,
    event_group: undefined,
    event_subgroup: undefined,
    core_event: 'yes',
    link_url: ga4payload?.linkUrl,
    section: ga4payload?.section,
    user_detail: undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendCartClickEvent = (
  products: QuickCartItemFragment[],
  eventType: string,
  gaCookie?: string
): void => {
  const eventLabel =
    eventType === EventValues.Checkout
      ? gaCookie
      : products?.map((product) => product?.material?.number).join(' | ')
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.YourCart,
      eventAction: eventType,
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendCartErrorEvent = (
  isMarketplaceCart: boolean,
  errorMessage: ReactElement,
  cartData?: CartFragment
): void => {
  try {
    const products = cartData?.items || []
    const cartType = isMarketplaceCart ? 'marketplace' : 'standard'
    const productsArray =
      clubbingUpProducts(products, undefined, cartType) || []

    // Convert the description to lowercase and set to undefined if empty
    const description = errorMessage?.props?.defaultMessage
      ? errorMessage.props.defaultMessage.toLowerCase()
      : undefined

    // Convert product details to undefined if empty
    const productId =
      productsArray.length > 0
        ? productsArray.map((item) => item.id).join('|')
        : undefined
    const productVariant =
      productsArray.length > 0
        ? productsArray.map((item) => item.variant).join('|')
        : undefined
    const productBrand =
      productsArray.length > 0
        ? productsArray.map((item) => item.brand).join('|')
        : undefined
    const productName =
      productsArray.length > 0
        ? productsArray.map((item) => item.name).join('|')
        : undefined

    legacySendEvent({
      payload: {
        event: 'exception',
        description: description,
        error_category: 'cart errors',
        product_id: productId,
        product_variant: productVariant,
        product_brand: productBrand,
        product_name: productName,
        cart_type: cartType,
        lot_number: undefined,
      },
    })
  } catch (error) {
    console.error('Error sending cart error event:', error)
  }
}

export const sendCheckoutEvent = (
  actionTitle: string,
  cartData?: CartFragment,
  isB2BUser = false,
  userIsLoggedIn = false,
  userErpType = '',
  isBlueErpIntegrationEnabled = false
): void => {
  try {
    if (!cartData) throw new Error()

    if (isTealium) {
      sendTealiumCartDataEvent({
        viewType: actionTitle,
        cartData,
        isB2BUser,
        userIsLoggedIn,
        userErpType,
        isBlueErpIntegrationEnabled,
      })
    }

    if (showGTMTag) {
      const products = cartData.items || []
      const currencyCode = cartData.currency
      const cartType =
        cartData.cartType === 'marketplacecart'
          ? Dimension91.Marketplace
          : Dimension91.Standard
      const productsArray =
        clubbingUpProducts(products, undefined, cartType) || []

      const packageHandlingKeys = [
        'iceCharge',
        'electricalFee',
        'eurVolatileMassCharge',
        'dutyTotal',
        'packageCharge',
      ]
      const packageHandlingCharges = packageHandlingKeys.reduce(
        (total, key) => total + (cartData?.totals?.[key] ?? 0),
        0
      )

      if (actionTitle === '1') {
        // GA4 - Shopping cart (view_cart)
        legacySendEvent({
          payload: {
            event: 'view_cart',
            addtocart_type: 'default',
            cart_type: cartType,
            shipping: priceFormatter(cartData?.totals?.shipping),
            packaging_handling: priceFormatter(packageHandlingCharges),
            ecommerce: {
              currency: getCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE),
              items: productsArray.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                coupon: item.coupon,
                item_brand: item.brand,
                item_variant: item.variant,
                price: priceFormatter(item?.price),
                quantity: item.quantity ?? 1,
                discount: item.discount ?? 0,
                item_type: cartType,
              })),
            },
          },
        })
      }

      if (actionTitle === '2') {
        const paymentMethod = mapPaymentMethod(
          cartData.selectedPaymentMethod ?? '',
          cartData.chargebackCodes.length > 0,
          cartData.contractNumber ?? ''
        )
        const shippingMethodDescription =
          cartData?.shippingPreference?.participantShippingMethods?.find(
            (method) =>
              method?.shippingMethodCode ===
              cartData?.shippingPreference?.shippingMethod
          )?.description

        // GA4 - Checkout page (begin_checkout)
        legacySendEvent({
          payload: {
            event: 'begin_checkout',
            addtocart_type:
              cartData.cartType === 'buyitnowcart' ? 'buy it now' : 'default',
            cart_type: cartType,
            payment_type: paymentMethod,
            shipping_tier: shippingMethodDescription,
            shipping: priceFormatter(cartData?.totals?.shipping),
            packaging_handling: priceFormatter(packageHandlingCharges),
            ecommerce: {
              currency: getCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE),
              items: productsArray.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                coupon: item.coupon,
                item_brand: item.brand,
                item_variant: item.variant,
                price: priceFormatter(item?.price),
                quantity: item.quantity ?? 1,
                discount: item.discount ?? 0,
                item_type: cartType,
              })),
            },
          },
        })
      }

      // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
      //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
      legacySendEvent({
        eventType: GTMEventTypes.Checkout,
        payload: {
          eventCategory: GTMEventCategory.Checkout,
          eventAction: actionTitle,
          eventLabel: productsArray?.map((item) => item.id).join('|'),
          eventInteractionType: 1,
          ecommerce: {
            currencyCode: getCurrencyCode(
              currencyCode || DEFAULT_CURRENCY_CODE
            ),
            checkout: {
              actionField: {
                step: actionTitle,
                ...(actionTitle === '1' || actionTitle === '2'
                  ? {
                      option:
                        cartData.cartType === 'marketplacecart'
                          ? actionTitle === '1'
                            ? 'marketplace'
                            : cartData.selectedPaymentMethod === 'CC'
                              ? 'marketplace cc'
                              : 'marketplace po'
                          : 'standard',
                    }
                  : {}),
              },
              ...(actionTitle === '1' ? { products: productsArray } : ''),
            },
          },
        },
      })
    }
  } catch (error) {
    console.error('ERROR - Checkout Event ', error)
  }
}

export const sendAddToCartSideRailEvent = (
  product: DynamicProductFragment,
  currency: string | null | undefined,
  pageName: AddToCartPagesEnum
): void => {
  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: GTMEventTypes.AddToCart,
      eventCategory: GTMEventCategory.AddToCart,
      eventAction: 'shopping cart - buy it again',
      eventLabel: product?.id,
      eventInteractionType: 0,
      ecommerce: {
        currencyCode: currency ? getCurrencyCode(currency) : null, //currency passed in from parent component in shopping cart
        add: {
          actionField: {
            list: 'shopping cart',
          },
          products: [
            {
              id: `${product?.id}`,
              name: `${product?.name}`,
              brand: `${product?.brand.name}`,
              quantity: 1,
              price: 1, //placeholder as requested to not break structure - Should be replaced with priceFormatter() when actual pricing data is passed.
              dimension91: 'standard',
            },
          ],
        },
      },
    },
  })

  // GA4 - Add To Cart
  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: {
        currency: currency ? getCurrencyCode(currency) : null, //currency passed in from parent component in shopping cart
        value: 1,
        items: [
          {
            item_id: product?.id,
            item_name: product?.name,
            item_brand: product?.brand?.name,
            discount: 0,
            item_list_id: pageName?.toLowerCase(),
            item_list_name: pageName?.toLowerCase(),
            item_variant: product?.productSku,
            price: 1, //placeholder as requested to not break structure - Should be replaced with priceFormatter() when actual pricing data is passed.
            item_type: 'standard',
            quantity: 1,
          },
        ],
      },
    },
  })
}

export const setAddToCartData = (
  values: CartItemInput[],
  materialPricing:
    | ValidMaterialPricingDetailFragment[]
    | QuoteItemFragment[]
    | OrderItemFragment[]
    | CurrentStagedMaterial[]
    | ScanNowMaterial[]
    | PromotionalBundleItemPricing[],
  pageName: AddToCartPagesEnum,
  gaMapProducts?: GAMapProducts,
  erpType?: string[] | Object,
  userType?: Object,
  bundle?: {
    quantity: number
    bundleId: string
  }
): void => {
  try {
    let products: Partial<GTMEcommerceItem>[] | undefined
    const currency = getCurrencyCodeFromProduct(materialPricing)
    switch (pageName) {
      case AddToCartPagesEnum.CustomList:
      case AddToCartPagesEnum.QuoteDetails:
      case AddToCartPagesEnum.ShoppingCart:
      case AddToCartPagesEnum.OrderDetails:
      case AddToCartPagesEnum.OrderDetailsOrderSummary:
      case AddToCartPagesEnum.SavedCart:
      case AddToCartPagesEnum.QuickOrder:
      case AddToCartPagesEnum.QuickOrderQuotesModal:
      case AddToCartPagesEnum.HeaderSearch:
      case AddToCartPagesEnum.BuyItAgainPage:
      case AddToCartPagesEnum.PromoBundle:
      case AddToCartPagesEnum.AccountDashboardQuote:
      case AddToCartPagesEnum.AccountDashboardOrder:
        products = (gaMapProducts || setMaterialData)(
          values,
          materialPricing,
          pageName,
          bundle
        )
        break
      default:
        products = (gaMapProducts || setPaClipData)(values, materialPricing)
        break
    }
    if (products && products.length) {
      const result = products.map((k) => {
        const cartItem = values.find(
          (v) => k.variant === v.materialNumber?.toLowerCase()
        )
        return {
          ...k,
          dimension91:
            materialPricing?.length && !!cartItem?.marketplaceOfferId
              ? Dimension91.Marketplace
              : Dimension91.Standard,
          ...(!showGTMTag && {
            erpType: mapProductErpType(
              getErpTypeParam(erpType, cartItem) || [],
              userType,
              pageName
            ),
          }),
        }
      })

      sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
        products: result,
        pageName,
        currency,
      })
    }
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
  }
}

export const sendBundleKitAddToCartEvent = (
  promoBundlePricing,
  bundleId,
  quantity = 1
): void => {
  const bundleItems = promoBundlePricing?.bundleItems || []
  const productsArray: GTMEcommerceItem[] = bundleItems.map((item) => {
    return {
      id: item?.materialId?.toLowerCase() || EventValues.Empty,
      name:
        item?.materialName
          ?.toLowerCase()
          .replace(regexForStripEncodingUnicode, '')
          .replace(regexForStripHTML, '')
          .replace(regexForStripSpecialChars, '') || EventValues.Empty,
      variant: item?.materialNumber?.toLowerCase() || EventValues.Empty,
      brand: item?.brand?.toLowerCase() || EventValues.Empty,
      quantity: quantity * item?.quantity || 1,
      price: priceFormatter(item?.yourPrice),
      dimension91: 'standard',
      coupon: bundleId,
    }
  })
  const addtocartPayload = {
    productIds:
      bundleItems.map((item) => item.materialId).join('|') || EventValues.Empty,
    actionTitle: 'promo bundle',
    ecommerce: {
      currencyCode: getCurrencyCode(
        bundleItems[0]?.currency || DEFAULT_CURRENCY_CODE
      ),
      add: {
        actionField: {
          list: 'promo bundle',
        },

        products: productsArray,
        dimension91: 'standard',
      },
    },
  }

  if (isTealium) {
    sendAddItemsToCartEvent({
      sessionStorageKey: ADD_TO_CART_GA_DATA_STORAGE_KEY,
    })
  }

  if (showGTMTag) {
    uaConfiguratorAddToCart(addtocartPayload)

    // GA4 - Add To Cart
    legacySendEvent({
      payload: {
        event: 'add_to_cart',
        addtocart_type: 'default',
        ecommerce: {
          currency: getCurrencyCode(
            promoBundlePricing?.currency || DEFAULT_CURRENCY_CODE
          ),
          value: bundleItems.reduce(
            (acc, bundle) =>
              (Number(bundle.yourPrice) || 1) * (bundle.quantity ?? 1) + acc,
            0
          ),
          items: bundleItems?.map((bundle) => ({
            item_id: bundle.materialId,
            item_name: bundle.materialName,
            discount: bundle.discount ?? 0,
            item_brand: bundle.brand,
            item_list_id: AddToCartPagesEnum.PromoBundle,
            item_list_name: AddToCartPagesEnum.PromoBundle,
            item_variant: bundle.materialNumber,
            price: priceFormatter(bundle?.yourPrice),
            item_type: 'standard',
            quantity: bundle.quantity ?? 1,
          })),
        },
      },
    })
  }
}

export const sendB2BBuyNowModalTransferCart = (): void => {
  const payload: GTMEcommerceValues | null =
    sessionStorage.getItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY)
  const subTotal = payload?.ecommerce?.purchase?.products.reduce(
    (acc, item) => (Number(item.price) * item.quantity || 0) + acc,
    0
  )
  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: 'purchase',
      addtocart_type: 'buy it now',
      order_type: 'b2b-standard',
      deep_order_id: undefined,
      order_participant_id: payload?.purchaseParticipantId || undefined,
      order_sold_to: payload?.purchaseSoldToNumber || undefined,
      order_bill_to: payload?.purchaseBillToNumber || undefined,
      order_ship_to: payload?.purchaseShipToNumber || undefined,
      payment_type: 'b2b',
      shipping_tier:
        payload?.ecommerce?.purchase?.actionField?.shippingMethod || undefined,
      ecommerce: {
        transaction_id: payload?.eventAction || undefined,
        value: subTotal || 1,
        tax: priceFormatter(payload?.ecommerce?.purchase?.actionField?.tax),
        shipping: priceFormatter(
          payload?.ecommerce?.purchase?.actionField?.shipping
        ),
        coupon: payload?.ecommerce?.purchase?.actionField?.coupon,
        currency: payload?.ecommerce?.currencyCode,
        items: payload?.ecommerce?.purchase?.products?.map((product) => ({
          item_id: product.id,
          item_name: product.name,
          coupon: product.coupon || undefined,
          discount: product.discount ?? 0,
          item_brand: product.brand,
          item_variant: product.variant,
          price: priceFormatter(product?.price),
          item_type: product.dimension91,
          quantity: product.quantity ?? 1,
        })),
      },
    },
  })
}

export const sendB2BBuyNowModalAddToCart = (): void => {
  const payload: GTMEcommerceValues | null =
    sessionStorage.getItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY)

  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: {
        currency: payload?.ecommerce?.currencyCode,
        value: payload?.ecommerce?.purchase?.products?.reduce(
          (acc, product) =>
            (Number(product.price) || 1) * (product.quantity ?? 1) + acc,
          0
        ),
        items: payload?.ecommerce?.purchase?.products?.map((product) => ({
          item_id: product.id,
          item_name: product.name,
          item_list_id: 'buy it now',
          item_list_name: 'buy it now',
          discount: product.discount ?? 0,
          item_brand: product.brand,
          item_variant: product.variant,
          price: priceFormatter(product?.price),
          item_type: product.dimension91,
          quantity: product.quantity ?? 1,
        })),
      },
    },
  })
}

export const sendAffiliationCheckoutClickEvent = (
  participantId: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: AffiliationCheckoutEventActionEnum.AffiliationPage,
      eventAction: AffiliationCheckoutEventActionEnum.CompleteCheckout,
      eventLabel: participantId,
      eventInteractionType: 0,
    },
  })
}
